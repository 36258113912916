import React from 'react';
import { Button, Container } from 'reactstrap';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import PageHeading from '../components/PageHeading/PageHeading';

const NotFoundPage = props => (
  <Layout>
    <PageHeading>Page Not Found</PageHeading>
    <div>
      <br />
      <Container>
        <h1>404</h1>
        <p>Sorry, the page you are looking for was not found.</p>
        <p>
          You may be looking for a resource that you were directly linked to.
          Note that the links have changed. Please look in the{' '}
          <Link to="/resources">Resources</Link> page for the file.
        </p>
        <Button size="lg" color="primary">
          <Link style={{ color: '#fff' }} to="/">
            Return Home
          </Link>
        </Button>
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  </Layout>
);

export default NotFoundPage;
